
<template>
  <div class="kongban">
    <div class="ground">
      <div class="box">
        <div
          class="up"
          :style="{
            padding: `${paddingW}px ${paddingH}px`,
            width: kongbanWidth + 'px',
          }"
        >
          <div
            class="container"
            v-for="item in cells"
            :key="item.name"
            :style="{
              width: cellSize + 'px',
              height: cellSize + 'px',
              margin: margin + 'px',
            }"
          >
            <div
              v-if="item.show"
              class="cell"
              :style="{
                backgroundColor: cellColor(item.score),
              }"
            ></div>
            <div class="popper" v-if="item.show">
              <div class="board">
                <div class="board-item">
                  <div>
                    <img
                      src="../assets/kongban/name.png"
                      alt="name"
                      class="icon"
                    />
                  </div>
                  <p class="cell-key">:&nbsp;&nbsp;{{ item.name }}</p>
                </div>
                <div class="board-item">
                  <div>
                    <img
                      src="../assets/kongban/value.png"
                      alt="name"
                      class="icon"
                    />
                  </div>
                  <p class="cell-value">:&nbsp;&nbsp;{{ item.score }}</p>
                </div>
              </div>
              <div class="tail">
                <div class="sjx"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="front"></div>
        <div class="left"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      cells: [],
      dark: [
        [96, 29, 192],
        [226, 131, 55],
        [239, 182, 77],
      ],
      light: [
        [218, 209, 238],
        [239, 182, 77],
        [106, 221, 128],
      ],
      small: 8 * 6,
      middle: 12 * 8,
      large: 17 * 11,
      xCnt: 8,
      yCnt: 6,
      paddingH: 10,
      paddingW: 20,
      margin: 5,
      kongbanWidth: 0,
      cellSize: 50,
    };
  },
  props: ["data"],
  methods: {
    cellColor(score) {
      let color = [];
      let k = 0;
      for (let i = 0; i < 3; i++) {
        color.push(
          this.dark[k][i] + (this.light[k][i] - this.dark[k][i]) * score
        );
      }
      return `rgb(${color[0]},${color[1]},${color[2]})`;
    },
    updateSize(xcnt, ycnt) {
      this.xCnt = xcnt;
      this.yCnt = ycnt;
      this.margin = ycnt < 15 ? 15 - ycnt : 20 - ycnt;
      this.cellSize = ycnt < 15 ? 50 - xcnt - ycnt : 60 - xcnt - ycnt;
      this.kongbanWidth =
        (this.cellSize + 2 * this.margin) * this.xCnt + 2 * this.paddingW;
    },
    fillCell(len) {
      console.log(len);
      this.cells.length = 0;
      for (let key in this.data) {
        this.cells.push({ name: key, score: this.data[key], show: 1 });
      }
      if (len <= 48) {
        this.updateSize(8, 6);
        for (let i = len; i < 48; i++) {
          this.cells.push({ name: `${i}`, score: 0, show: 0 });
        }
      } else if (len <= 96) {
        this.updateSize(12, 8);
        for (let i = len; i < 96; i++) {
          this.cells.push({ name: `${i}`, score: 0, show: 0 });
        }
      } else if (len <= 187) {
        this.updateSize(17, 11);
        for (let i = len; i < 187; i++) {
          this.cells.push({ name: `${i}`, score: 0, show: 0 });
        }
      } else if (len <= 368) {
        this.updateSize(23, 16);
        for (let i = len; i < 368; i++) {
          this.cells.push({ name: `${i}`, score: 0, show: 0 });
        }
      } else {
        this.updateSize(26, 18);
        for (let i = len; i < 464; i++) {
          this.cells.push({ name: `${i}`, score: 0, show: 0 });
        }
      }
    },
  },
  created() {
    const len = this.data ? Object.keys(this.data).length : 0;
    this.fillCell(len);
    console.log(len);
  },
  computed: {},
  watch: {
    data(oldData, newData) {
      /*3种规格，6*8，装载48及以下的类别，
    8*12，装载88、66、50三个类别，
    11*17装载184和 143的类别*/
      const len = this.data ? Object.keys(this.data).length : 0;
      this.fillCell(len);
    },
  },
  updated() {},
};
</script>

<style scoped>
.kongban {
  display: flex;
  justify-content: center;
  align-items: center;
  transform-style: preserve-3d;
  perspective: 5000px;
}

.ground {
  transform: rotateX(50deg) rotateZ(-45deg) rotateY(0deg);
  transform-style: preserve-3d;
  display: flex;
  justify-content: center;
  align-items: center;
}

.box {
  position: relative;
  transform-style: preserve-3d;
}

.up {
  box-sizing: border-box;
  transform: translateZ(40px);
  background: rgba(233, 245, 249);
  display: flex;
  align-items: flex-start;
  justify-content: center;
  align-content: flex-start;
  flex-wrap: wrap;
  transform-style: preserve-3d;
}

.container {
  box-sizing: border-box;
  transform-style: preserve-3d;
  border-radius: 50%;
  display: flex;
  align-items: flex-end;
  position: relative;
  background: linear-gradient(to right, rgb(200, 221, 225), rgb(80, 137, 178));
}

.cell {
  width: 80%;
  height: 80%;
  border-radius: 100%;
  border-bottom-right-radius: 50%;
  border-top-left-radius: 50%;
  cursor: pointer;
  transform-style: preserve-3d;
}

.popper {
  width: 150px;
  height: 160px;
  position: absolute;
  bottom: 50%;
  left: 50%;
  transform-origin: bottom;
  transform: translateX(-50%) rotateX(-90deg);
  transform-style: preserve-3d;
  display: none;
}

.popper .tail .sjx {
  content: " ";
  width: 0px;
  height: 0px;
  border-style: solid;
  /* border-width: tail的height，tail的width的一半 ,0*/
  border-width: 48px 70px 0;
  border-color: rgba(247, 210, 59, 0.75) transparent transparent;
}

.popper .board {
  width: 100%;
  height: 70%;
  border-radius: 10px;
  background: rgb(61, 131, 198);
  /* padding: 5px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: translateZ(1px);
  justify-content: space-evenly;
  z-index: 999;
}

.cell-key {
  white-space: nowrap;
  overflow:hidden;
  text-overflow: ellipsis;
}

.popper .tail {
  width: 100%;
  height: 30%;
  display: flex;
  justify-content: center;
  transform: translateY(-2px);
}

.cell:hover + .popper {
  display: block;
}

.board-item {
  display: flex;
  color: white;
  width: fit-content;
  font-size: 25px;
}

.board-item div {
  flex: 1;
  text-align: center;
}

.board-item p {
  width: fit-content;
  /* font-weight: bold; */
  text-align: start;
  overflow: hidden;
}

.icon {
  width: 25px;
}

.front {
  /* transform-style: preserve-3d; */
  width: 100%;
  height: 40px;
  background-image: linear-gradient(
    to right,
    rgb(61, 138, 240),
    rgb(104, 226, 249)
  );
  transform-origin: top;
  position: absolute;
  top: 100%;
  left: 0px;
  right: 0px;
  transform: rotateX(90deg);
}
.left {
  width: 40px;
  height: 100%;
  background-image: linear-gradient(
    to bottom,
    rgb(47, 110, 200),
    rgb(29, 55, 146)
  );
  transform-origin: right;
  position: absolute;
  top: 0px;
  right: 100%;
  bottom: 0px;
  transform: rotateY(90deg);
}
</style>