<template>
  <div class="service">
    <kongban class="kongban"></kongban>
  </div>
</template>

<script>
import kongban from "../components/kongban.vue";

export default {
  data() {
    return {};
  },
  components: {
    kongban,
  },
};
</script>

<style scoped>
.kongban {
  width: 900px;
  height: 600px;
}
</style>
